@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";


.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 50%;
  background: #B2B2B2;
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
}

.swiper-pagination-fraction.swiper-pagination-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #66594F !important;
  opacity: 1 !important;
  min-width: 43px;
  min-height: 24px;
  border-radius: 12px;
  margin-right : 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.swiper-pagination-bullet-active {
  background: white;
}

html {

}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tabular-lining-numbs {
  font-variant-numeric: tabular-nums;
  font-variant-numeric: lining-nums;
}

.clip-circle {
  clip-path: circle(50% at 50% 50%);
}

.ui-article-head {
  margin-top: -56px;
  min-height: 56px;
}

.article-image {
  padding-top: 48px;
}

.article-no-image {
  padding-top: 98px;
}

@media all and (min-width: 768px) {
  .ui-article-head {
    margin-top: -180px;
    min-height: 180px;
  }
  .article-image {
    padding-top: 392px;
  }
  .article-no-image {
    padding-top: 136px;
  }
}

span {
  &.bullet {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
}

* {
  font-variant: lining-nums;
}

.grecaptcha-badge {
  z-index: 1000;
  visibility: hidden;
}

.z-1001 {
  z-index: 1001;
}

.z-1002 {
  z-index: 1002;
}

.loader {
  border-top-color: #8FD200;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//@media (min-width: 768px){
//  .sm\:w-8\/24{
//    width: 33.33333334%;
//  }
//  .sm\:w-12\/24{
//    width: 50%;
//  }
//  .sm\:w-16\/24{
//    width: 66.66666668%;
//  }
//}

@media all and (max-width: 768px) {
  .a20-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .a40-wrapper {
    .a40-slot-image {
      padding: 8px 8px 8px 8px;
    }

    &:nth-of-type(2n+1) {
      .a40-slot-image {
        padding: 8px 8px 8px 0;
      }
    }

    &:nth-of-type(2n+2) {
      .a40-slot-image {
        padding: 8px 0 8px 8px;
      }
    }

    &:nth-of-type(2n+3) {
      .a40-slot-border {
        display: none;
      }
    }

  }
  .text-to-speech {
    position: absolute;
    top: 85px;
    left: 0;
  }
  .a50-slot-border {
    display: none;
  }
  .a60-slot-border {
    display: none;
  }
}

@media all and (min-width: 769px) {
  .a20-wrapper {
    &:nth-of-type(3n+1) {
      margin-left: calc((100% / 24) * 1);
      margin-right: calc((100% / 24) * 1);
    }

    &:nth-of-type(3n+2) {
      margin-right: calc((100% / 24) * 1);
    }

    &:nth-of-type(3n+3) {
      margin-right: 0;
    }
  }
  .a40-wrapper {
    .a40-slot-image {
      padding: 16px 24px 16px 24px;
    }

    &:nth-of-type(4n+1) {
      .a40-slot-image {
        padding: 16px 24px 16px 0;
      }
    }

    &:nth-of-type(4n+2) {
      .a40-slot-image {
        padding: 16px 24px 16px 24px;
      }
    }

    &:nth-of-type(4n+3) {
      .a40-slot-image {
        padding: 16px 24px 16px 24px;
      }
    }

    &:nth-of-type(4n+4) {
      .a40-slot-image {
        padding: 16px 0 16px 24px;
      }
    }

    &:nth-of-type(4n+5) {
      .a40-slot-border {
        display: none;
      }
    }
  }

  .a50-wrapper {
    .a50-slot-border {
      display: none;
    }

    &:nth-of-type(3n+2) {
      .a50-slot-border {
        display: flex;
      }
    }

    &:nth-of-type(3n+3) {
      .a50-slot-border {
        display: flex;
      }
    }
  }

  .a60-wrapper {
    &:nth-of-type(2n+1) {
      .a60-slot {
        padding: 24px 24px 24px 0;
      }
    }

    &:nth-of-type(2n+2) {
      .a60-slot {
        padding: 24px 24px 24px 24px;
      }
    }

    .a60-slot-border {
      display: none;
    }

    &:nth-of-type(2n+2) {
      .a60-slot-border {
        display: flex;
      }
    }

    &:nth-of-type(2n+2) {
      .a60-slot-border {
        display: flex;
      }
    }
  }
}

@media all and (min-width: 1140px) {
  .a20-wrapper {
    &:nth-of-type(3n+1) {
      margin-left: calc((100% / 24) * 1);
      margin-right: calc((100% / 24) * 1);
    }

    &:nth-of-type(3n+2) {
      margin-right: calc((100% / 24) * 1);
    }

    &:nth-of-type(3n+3) {
      margin-right: 0;
    }
  }
}


[type='radio'] {
  width: 24px;
  height: 24px;
  border-color: #6a6a6a
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 8 8' fill='#003845' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='2'/%3e%3c/svg%3e");
  width: 24px;
  height: 24px;
}


[type='checkbox']:checked, [type='radio']:checked {
  border-width: 0;
}


.filter {
  [type='radio']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 8 8' fill='#ffffff' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='2'/%3e%3c/svg%3e");
  }

  [type='checkbox']:checked:hover, [type='checkbox']:checked:focus, [type='radio']:checked:hover, [type='radio']:checked:focus {
    border-color: #003845;
    border-width: 8px;
  }

  [type='radio']:checked {
    border-color: #003845;
    background-color: white;
    border-width: 8px;
  }

  [type='checkbox']:checked {
    border-color: #003845;
    background-color: #003845;
    border-width: 5px;
  }
}


.ou-logo-main {
  width: 390px;
  height: 71px;
}

@media all and (max-width: 768px) {
  .ou-logo-main {
    width: 240px;
    height: 44px;
  }
}

.link-external-icon {
  display: none;
}

.link-external {
  .link-external-icon {
    display: flex;
  }
}


html[class*="userway"] {
  .overlay-link {
    //z-index: -20;
    width: 0;
    height: 0;
  }
}

.fa-fix {
  svg {
    vertical-align: 0;
  }
}

@media all and (max-width: 768px) {
  .ui-dipl-grid {
    column-count: 1;
  }
}

@media all and (min-width: 769px) and (max-width: 1024px) {
  .ui-dipl-grid {
    column-count: 2;
  }
}

@media all and (min-width: 1024px) {
  .ui-dipl-grid {
    column-count: 3;
  }
}

.w-break {
  word-break: break-word;
}


/* Settings */
:root {
  --offset: 0;
  --max_width: 1440px;
  --columns: 12;
  --gutter: 16px;
  --baseline: 1rem;
  --baseline-shift: calc(var(--baseline) / 2);
  --line-thickness: 0;
  --color: rgba(0, 0, 0, 0.06);
  --color-text: rgba(0, 0, 0, 0.06);
  --media-query: 'base';
}

@media (min-width: 35em) {
  :root {
    --offset: 0;
    --columns: 24;
    --gutter: 16px;
    --baseline: 1.5rem;
    --color: rgba(0, 0, 0, 0.06);
    --color-text: rgba(0, 0, 0, 0.06);
    --media-query: 'small';
  }
}

@media (min-width: 48em) {
  :root {
    --offset: 0;
    --columns: 24;
    --gutter: 16px;
    --baseline: 2rem;
    --color: rgba(0, 0, 0, 0.06);
    --color-text: rgba(0, 0, 0, 0.06);
    --media-query: 'medium';
  }
}

@media (min-width: 70em) {
  :root {
    --offset: 0;
    --baseline: 3rem;
    --color: rgba(0, 0, 0, 0.06);
    --color-text: rgba(0, 0, 0, 0.06);
    --media-query: 'large';
  }
}


/* Helper variables */
:root {
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(
      to right,
      var(--color),
      var(--color) var(--column-width),
      transparent var(--column-width),
      transparent var(--repeating-width)
  );
  --background-baseline: repeating-linear-gradient(
      to bottom,
      var(--color),
      var(--color) 0,
      transparent 0,
      transparent var(--baseline)
  );
}

.container {
  position: relative;
}

.container::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - (2 * var(--offset)));
  max-width: var(--max_width);
  min-height: 100vh;
  //content: '';
  background-image: var(--background-columns), var(--background-baseline);
  background-size: var(--background-width) 100%;
  background-position: 0 var(--baseline-shift);
  z-index: 0;
  pointer-events: none;
}

.container::after {
  //content: var(--media-query);
  position: fixed;
  top: 1rem;
  left: 1rem;
  color: var(--color-text);
}



@media print {
  header, footer, app-article-social {
    display: none;
  }
  * {
    color: black !important;
  }
  .ui-article {
    * {
      font-size: 16pt !important;
    }
  }
  @page footer {
    display: none
  }

  @page header {
    display: none
  }
  @page {
    size: 330mm 427mm;
    margin: 10mm 0;
  }
  .print {
    page-break-after: always;
  }
  .print thead {
    display: table-header-group;
  }
  .print tfoot {
    display: table-footer-group;
  }

  .print .print-article {
    page-break-inside: avoid;
  }

  #header,
  #article-utilities,
  #article-scroll-to-top,
  #article-docs,
  #article-poll {
    display: none;
  }
  .sm\:w-15\/24, .sm\:w-8\/24 {
    width: 100%
  }
  .sm\:ml-1\/24 {
    margin-left: 0;
  }
  .sm\:pl-1\/24 {
    padding-left: 0;
  }


}

//Flatpicker styles
.ng2-flatpickr-wrapper {
  background: white;
  border: 1px solid #66594F;
  display: flex;
  align-items: flex-end;
  height: 42px;

  &:focus-within {
    border-color: #66594F;
  }
}

.has-error .ng2-flatpickr-wrapper {
  color: #e03c31;
  border-color: #e03c31;
  display: flex;
  justify-content: center;
  justify-items: center;

  &:focus {
    border-color: #e03c31;
    color: #e03c31;
  }
}

.flatpickr-weekdays {
  margin-top: 8px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #A59080;
}

.has-error .ng2-flatpickr-wrapper label {
  color: #e03c31;
}

.ng2-flatpickr-wrapper input {
  position: absolute;
  inset: 0;
  font-weight: 500;
  background-color: transparent;
  border: none;
  line-height: 1.25;

  &:focus {
    border: none;
  }
}

.ng2-flatpickr-input {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.ng2-flatpickr-wrapper {
  label {
    font-size: 18px;
    color: #A2A3A3;
    line-height: 1;
  }
}

input[type=text] {
  --tw-ring-color: #66594F !important;
}

.ng2-flatpickr-wrapper {
  position: relative;

  label {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #A2A3A3;
    left: 12px;
    top: 8px;
  }


  & > input:not(:placeholder-shown) ~ label {
    font-size: 14px;
    font-weight: 400;
    top: 7px;
  }
}

.flatpickr-calendar {
  margin-top: 4px;
  border: 1px solid #66594F !important;
  border-radius: 0;
  width: 384px !important;
  overflow: hidden;
  height: 317px;
  padding: 8px 26px 26px 32px !important;

  &:before {
    border-bottom-color: #66594F !important;
  }
}

.flatpickr-rContainer {
  width: 100% !important;

}

.dayContainer {
  width: 318px !important;
  min-width: 318px !important;
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}


.flatpickr-days {
  width: 100% !important;
}

.flatpickr-prev-month {
  left: 32px !important;
  top: 8px !important;
}

.flatpickr-next-month {
  right: 32px !important;
  top: 8px !important;
}

.flatpickr-months,
.flatpickr-innerContainer {
}

.flatpickr-day {
  color: #464646 !important;
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 24px;
  border-radius: 0 !important;
  display: flex;
  justify-items: center;
  align-items: center;
  width: 45px !important;

  &:hover {
    border-color: transparent;
    background: #EDE9E6 !important;
  }

  &.today {
    border-color: white !important;
  }

  &.flatpickr-disabled {
    color: #D1D1D1 !important;
  }

  &.selected {
    color: white !important;
    border-color: #66594F !important;
    background-color: #66594F !important;
  }
}

.flatpickr-month {

}

.prevMonthDay {
  color: #DEDEDE !important;
}

.nextMonthDay {
  color: #DEDEDE !important;
}


.ng2-flatpickr-input-container input[type=text] {
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins;
  color: #66594F;

  &:focus {
    outline: 0;
  }
}

@media (max-width: 768px) {
  .flatpickr-calendar {
    width: 326px !important;
    padding: 0 0 0 0 !important;
  }

  .flatpickr-day {
    width: 46px !important;
  }

  .dayContainer {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 1176px) {

  .flatpickr-day {
    color: #464646 !important;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 24px;
    border-radius: 0 !important;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 20px !important;

    &:hover {
      border-color: transparent;
      background: #EDE9E6 !important;
    }

    &.today {
      border-color: white !important;
    }

    &.flatpickr-disabled {
      color: #D1D1D1 !important;
    }

    &.selected {
      color: white !important;
      border-color: #66594F !important;
      background-color: #66594F !important;
    }
  }

  .ng2-flatpickr-input-container input[type=text] {
    font-size: 12px;
    line-height: 24px;
    font-family: Poppins;
    color: #66594F;

    &:focus {
      outline: 0;
    }
  }
}

.flatpickr-prev-month,
.flatpickr-next-month {
  padding: 0 !important;
  height: 43px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 34px !important;

  &:hover {
    svg {
      fill: #595478 !important
    }
  }
}

.w-full{
  .module-d-100,
  .module-d-200{
    //padding: 0 0 40px 0;
  }
}

