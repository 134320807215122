.ui-select{
  border: none;
  border-bottom: 1px solid #c2d0d0;
  background-color: transparent;
  padding: 12px 60px 12px 0;
  background-position: 100% 8px;
  line-height: 1;
}

.ui-input{
  border: none;
  border-bottom: 1px solid #c2d0d0;
}
